@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

:root {
  font-size: 18px;
  font-weight: 400;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

.heading {
  padding: 1rem 0;
  font-size: 3.5rem;
  text-align: center;
}

.swiper_container {
  height: 52rem;
  padding: 2rem 0;
  position: relative;
}

.swiper-wrapper {
  @apply !w-screen;
}

.swiper-slide {
  @apply !relative !h-full !w-auto !scale-[.8];
  transform-origin: top;
}

.swiper-slide.swiper-slide-active {
  @apply !scale-100;
}

.swiper-slide.swiper-slide-active .swiper-caption {
  @apply opacity-100 duration-300;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.swiper-navigation .swiper-button-prev,
.swiper-navigation .swiper-button-next {
  @apply !m-0 !aspect-square cursor-pointer items-center justify-center rounded-lg border border-neutral-4 text-lg text-neutral-4 hover:border-neutral-6 hover:text-neutral-6;
  position: static;
  left: auto;
  right: auto;
}

/* .swiper-navigation .swiper-button-prev svg,
.swiper-navigation .swiper-button-next svg {
  @apply h-6 w-6;
} */

.swiper-navigation .slider-arrow::after {
  content: '';
}

.swiper-pagination {
  @apply !relative;
}

.swiper-pagination-fraction {
  bottom: 0;
}
